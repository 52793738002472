<template>
  <Menubar :model="items">
    <template #start>
      استرجاع البيانات
    </template>
    <template #end>
      <Button
        label="رجوع للوحه"
        @click="$router.push('/admin')"
        icon="pi pi-power-off"
        class="p-button-danger"
      />
    </template>
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar';
export default {
  components: {
    Menubar,
  },
  name: 'app-header',
  data() {
    return {
      items: [
        // {
        //   label: 'الرئيسيه',
        //   icon: 'pi pi-fw pi-home',
        //   to: '/admin',
        // },
      ],
      logo: null,
    };
  },
  methods: {
    logout() {
      delete localStorage.admin;
      location.reload();
    },
    getLeague() {
      this.$http
        .post(`league/search`, {
          select: ['image'],
          where: {
            status: true,
          },
          order: {
            id: 'DESC',
          },
        })
        .then((res) => {
          const list = res.data;
          if (list.length > 0) {
            this.logo = this.$baseUploadURL + list[0].image;
          } else {
            this.logo = '/images/logo.png';
          }
        });
    },
  },
  created() {
    this.getLeague();
    const user = JSON.parse(localStorage.admin);
    const roles = user.roles ?? JSON.parse(user.roles);
    // if (roles.includes('logsVeiw')) {
    //   this.items.push({
    //     label: 'المرقبه',
    //     icon: 'pi pi-fw pi-shield',
    //     to: '/admin/logs',
    //   });
    // }

    if (roles.includes('adminsVeiw')) {
      this.items.push({
        label: 'المديرين',
        icon: 'pi pi-fw pi-user',
        to: '/soft/admins',
      });
    }

    if (roles.includes('leagueVeiw')) {
      this.items.push({
        label: 'المسابقات',
        icon: 'pi pi-map-marker',
        to: '/soft/league',
      });
    }

    if (roles.includes('leagueVeiw')) {
      this.items.push({
        label: 'المواليد المشاركه',
        icon: 'pi pi-fw pi-calendar-plus',
        to: '/soft/years',
      });
    }

    if (
      roles.includes('academiesVeiw') ||
      roles.includes('coachVeiw') ||
      roles.includes('mindfulVeiw') ||
      roles.includes('technicalManagerVeiw') ||
      roles.includes('playersVeiw')
    ) {
      const list = [];

      if (roles.includes('academiesVeiw')) {
        list.push({
          label: 'الاكاديميات',
          icon: 'pi pi-fw pi-sitemap',
          to: '/soft/academies',
        });
      }
      if (roles.includes('coachVeiw')) {
        list.push({
          label: 'المدربين',
          icon: 'pi pi-fw pi-ticket',
          to: '/soft/coach',
        });
      }

      if (roles.includes('mindfulVeiw')) {
        list.push({
          label: 'الادريين',
          icon: 'pi pi-fw pi-star-o',
          to: '/soft/mindful',
        });
      }

      if (roles.includes('technicalManagerVeiw')) {
        list.push({
          label: 'المدرين الفنين',
          icon: 'pi pi-fw pi-link',
          to: '/soft/technical_manager',
        });
      }

      if (roles.includes('playersVeiw')) {
        list.push({
          label: 'اللاعبين',
          icon: 'pi pi-fw pi-users',
          to: '/soft/players',
        });
      }

      // if (roles.includes('movePlayersVeiw')) {
      //   list.push({
      //     label: 'نقل اللاعبين',
      //     icon: 'pi pi-fw pi-sort-alt',
      //     to: '/admin/players-move',
      //   });
      // }

      this.items.push({
        label: 'الاكاديميات',
        icon: 'pi pi-fw pi-sitemap',
        items: list,
      });
    }

    if (
      roles.includes('carnicsVeiw') ||
      roles.includes('ascorchitVeiw') ||
      // comant
      roles.includes('mindfulVeiw') ||
      roles.includes('technicalManagerVeiw') ||
      roles.includes('playersVeiw')
    ) {
      const list = [];

      if (roles.includes('carnicsVeiw')) {
        list.push({
          label: 'الكارنيهات',
          icon: 'pi pi-fw pi-id-card',
          to: '/soft/carnics',
        });
      }
      if (roles.includes('certificatesVeiw')) {
        list.push({
          label: 'الشهادات',
          icon: 'pi pi-fw pi-image',
          to: '/soft/certificates',
        });
      }

      if (roles.includes('ascorchitVeiw')) {
        list.push({
          label: 'اسكورشيت',
          icon: 'pi pi-fw pi-list',
          to: '/soft/ascorchit',
        });
      }
      // if (roles.includes('championshipVeiw')) {
      //   list.push({
      //     label: 'جدول البطوله',
      //     icon: 'pi pi-fw pi-table',
      //     to: '/soft/championship',
      //   });
      // }

      // if (roles.includes('championshipVeiw')) {
      //   list.push({
      //     label: 'نتائج البطوله',
      //     icon: 'pi pi-fw pi-sort',
      //     to: '/soft/score',
      //   });
      // }

      if (roles.includes('newsVeiw')) {
        list.push({
          label: 'الأخبار',
          icon: 'pi pi-fw pi-tag',
          to: '/soft/news',
        });
      }

      this.items.push({
        label: 'المزيد',
        icon: 'pi pi-fw pi-th-large',
        items: list,
      });
    }
  },
};
</script>
